/**
 *  1. Browser History - Tabs
 *    1.1. Tabs Control
 *    1.2. Hash Control
 *    1.3. Iframe Hash Store
 *    1.4. Link Target Locator
 *    1.5. Start scripts
 *    1.6. Modal Control
 *    1.7  Time variables
 *    1.8  Utils
  */

/**
 *  1. Browser History
 */

/**
 *  1.1. Tabs Control
 */
var TabsControl = {
    tabs: {},
    defaultTab: {}
};

$('#outlet-content').on('content-replace-before', function() {
    TabsControl.tabs = {};
    TabsControl.defaultTab = {};
});

TabsControl.setDefaultTab = function(name, default_tab_id, tabs_number) {
    for (var i = 1; i <= tabs_number; i++) {
        if (default_tab_id != i) {
            $("#" + name + "_content_" + i).css("display", "none");
        }
    }
    TabsControl.tabs[name] = 1;
    var hashTabNo = TabsControl.getHashTabNo(name);
    if (hashTabNo == -1) {
        TabsControl.setTab(name, default_tab_id, true);
    } else {
        //TabsControl.tabs[name] = hashTabNo;
    }
};
TabsControl.setTabHash = function(tabHash, replace) {
    var pair = tabHash.substring(1).split("=");
    TabsControl.setTab(pair[0], pair[1], replace);
};
TabsControl.setTab = function(name, id, replace) {
    HashControl.setParam(name, id, replace);
};
TabsControl.getHashTabNo = function(name) {
    var value = HashControl.getParam(name, -1);
    var number = Number(value);
    return isNaN(number) ? -1 : number;
};
TabsControl._intervalFixHeights = null;
TabsControl._selectTab = function(name, id) {
    var $header = $("#" + name + "_header_" + id);
    if ($header.length == 0) {
        ComponentsManager.setPageTimeout(
            function()  {
                TabsControl.setTab(name,
                    TabsControl.tabs[name],
                    true);
            }
            , 100);
        return;
    }

    var $tab = $("#" + name + "_content_" + id);

    var $oldHeader = $("#" + name + "_header_" + TabsControl.tabs[name]);
    var $oldHeader_decoration = $oldHeader.parents(".tab_status").eq(0);
    $oldHeader_decoration.removeClass("active opened");
    var $oldTab = $("#" + name + "_content_" + TabsControl.tabs[name]);
    $oldTab.hide();

    var $header_decoration = $header.parents(".tab_status").eq(0);
    $header_decoration.addClass("active opened");
    $tab.show();

    TabsControl.tabs[name] = id;
};
TabsControl._onHashChange = function(hash) {
    for (var name in this.tabs) {
        if (this.tabs.hasOwnProperty(name)) {
            var tabNo = TabsControl.getHashTabNo(name);
            TabsControl._selectTab(name, tabNo);
        }
    }
};

/**
 *  1.2. Hash Control
 */
var HashControl = {
    hash: "",
    hashChangeListeners: [],
    inited: false,
    timeout: null
};

$('#outlet-content').on('content-replace-before', function() {
    HashControl.hashChangeListeners = [];
    HashControl.hash = "";
    HashControl.inited = false;
    HashControl.timeout = null;
});

HashControl.setHash = function(hash, locationReplace) {
    locationReplace = locationReplace || false;
    if (locationReplace) {
        var href = document.location.href.replace(/#.*/, "");
        href += hash;
        document.location.replace(href);
    } else {
        if (history.pushState) {
            history.pushState(null, document.title, hash);
        } else {
            document.location.hash = hash;
        }
    }
};
HashControl.addHashChangeListener = function(func) {
    this.hashChangeListeners.push(func);
};
HashControl._timedUrlCheck = function() {
    var hash = document.location.hash;
    if (this.hash != hash) {
        for (var i=0; i < this.hashChangeListeners.length; i++) {
            this.hashChangeListeners[i].call(window, hash);
        }
        this.hash = hash;
    }
    if (this.inited) {
        this.timeout = setTimeout(function() {
            HashControl._timedUrlCheck();
        }, 100);
    }
};
HashControl.init = function() {
    if (this.inited) {
        return;
    }
    this.inited = true;
    HashControl._timedUrlCheck();
};
HashControl.stop = function() {
    clearTimeout(this.timeout);
    this.inited = false;
};
HashControl.getParam = function(name, defaultValue) {
    var hashString = document.location.hash;
    if (hashString.length > 0) {
        hashString = hashString.substring(1);
    } else {
        return defaultValue;
    }
    var pairs = hashString.split("&");
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split("=");
        if (pair[0] == name) {
            if (!pair[1]) {
                return defaultValue;
            }
            return pair[1];
        }
    }
    return defaultValue;
};
HashControl.setParam = function(name, value, locationReplace) {
    var oldValue;
    var found = false;
    var pairs = [];
    var hashString = document.location.hash;
    if (hashString.length > 0) {
        hashString = hashString.substring(1);
    }
    if (hashString.length > 0) {
        pairs = hashString.split("&");
    }
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split("=");
        if (pair[0] == name) {
            oldValue = pair[1];
            if (oldValue != value) {
                pairs[i] = name + "=" + value;
            }
            found = true;
            break;
        }
    }
    if (!found) {
        pairs.push(name + (value !== undefined
            ? "=" + value
            : "")
        );
    }
    HashControl.setHash("#" + pairs.join("&"), locationReplace);
    return oldValue;
};


/**
 * Method  HashControl.setParams
 * ---
 * It is used to support hashes when they are in the form of multi,
 * eg. #hashName=1,2,3.
 * @param  { string } name
 * @param  { string } value
 * @param  { string } locationReplace
 */
HashControl.setParams = function(name, value, locationReplace) {
    var oldValue,
        found = false,
        pairs = [],
        hashString = document.location.hash;

    if (hashString.length > 0) {
        hashString = hashString.substring(1);
        pairs = hashString.split("&");
    }

    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split("=");
        if (pair[0] == name) {
            oldValue = pair[1];
            if (oldValue != value) {
                pairs[i] = name + "=" + oldValue + ',' + value;
            }
            found = true;
            break;
        }
    }

    if (!found) {
        pairs.push(name + (value !== undefined
            ? "=" + value
            : "")
        );
    }

    HashControl.setHash("#" + pairs.join("&"), locationReplace);
    return oldValue;
};
/**
 * Method  HashControl.RemoveHashSign
 * ---
 * Responsible for removing the hash itself if it remains in the url,
 * assuming it has no values.
 */
HashControl.RemoveHashSign = function() {
    var loc = window.location;
    if ("pushState" in history) {
        history.pushState("", document.title, loc.pathname + loc.search);
    }
};
/**
 * Method  HashControl.removeParam
 * ---
 * Is responsible for removing a single hash from the url.
 * @param  { string } name
 * @param  { string } value
 * @param  { string } locationReplace
 */
HashControl.removeParam = function(name, value, locationReplace) {
    var pairs = []
        hashString = document.location.hash;

    if (hashString.length > 0) {
        hashString = hashString.substring(1);
        pairs = hashString.split("&");
    }

    if (!pairs.length) return;

    var pairsToSave = pairs.filter(function(pair) {
        return pair !== (name + "=" + value);
    });

    HashControl.setHash("#" + pairsToSave.join("&"), locationReplace);

    /**
     * On IE11, if there is no hash, it takes the value
     * eg. url#
     */
    if (document.location.hash === '' || document.location.hash === '#') HashControl.RemoveHashSign();
};
/**
 * Method  HashControl.removeParam
 * ---
 * Is responsible for removing a multiple values hash from the url.
 * @param  { string } name
 * @param  { string } value
 * @param  { string } locationReplace
 */
HashControl.removeParams = function(name, value, locationReplace) {
    var pairs = [],
        hashString = document.location.hash,
        pairsIndex;

    if (hashString.length > 0) {
        hashString = hashString.substring(1);
        pairs = hashString.split("&");
    }

    if (!pairs.length) return;

    for (var i = 0; i < pairs.length; i++) {
        var hash = pairs[i].split("=");
        if (hash[0] == name) {
            pairsIndex = i;
            break;
        }
    }

    var hashValues = pairs[pairsIndex].split('=')[1].split(','),
        hashToSave = hashValues.filter(function(hashValue) {
            return hashValue !== value;
        }),
        hashBuilder = name + '=' + hashToSave.join(',');

    if (hashToSave.length === 0) {
        pairs[pairsIndex] = '';
    } else {
        pairs[pairsIndex] = hashBuilder;
    }

    var filteredPairs = pairs.filter(function(pair) {
        return pair !== '';
    });

    HashControl.setHash("#" + filteredPairs.join("&"), locationReplace);

    /**
     * On IE11, if there is no hash, it takes the value
     * eg. url#
     */
    if (document.location.hash === '' || document.location.hash === '#') HashControl.RemoveHashSign();
}
HashControl.getNonPairedHashElements = function() {
    var hashString = document.location.hash;
    var pairs = [];
    var found = [];
    if (hashString.length > 0) {
        hashString = hashString.substring(1);
    }
    if (hashString.length > 0) {
        pairs = hashString.split("&");
    }
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split("=");
        if (!pair[1]) {
            found.push(pair[0]);
        }
    }

    return found;
}

/**
 *  1.3. Iframe Hash Store
 */
var IframeHashStore = {
    iframe: null,
    interval: null,
    hash: "",
    count: 0,
    inited: false
};
IframeHashStore.init = function(iframe) {
    if (this.inited) {
        return;
    }
    this.inited = true;
    this.iframe = typeof iframe == "string"
        ? document.getElementById(iframe)
        : iframe;
    if (this.iframe == null) {
        return;
    }
    IframeHashStore.storeHash(document.location.hash);
    HashControl.addHashChangeListener(function(hash) {
        IframeHashStore._onHashChange(hash);
    });
};
IframeHashStore._onHashChange = function(hash) {
    this.storeHash(hash);
};
IframeHashStore._intervalCheck = function() {
    if(!this.iframe.contentWindow || !this.iframe.contentWindow.document.body) {
        return;
    }
    var storedHash = this.iframe.contentWindow.document.getElementById("state").innerHTML.replace(/&amp;/g, "&");
    if (this.hash != storedHash) {
        this.hash = storedHash;
        HashControl.setHash(storedHash);
    }
};
IframeHashStore.storeHash = function(hash) {
    if (hash == this.hash) {
        return;
    }
    if(!this.iframe.contentWindow || !this.iframe.contentWindow.document.body) {
        setTimeout(function() { IframeHashStore.storeHash(hash); }, 10);
        return;
    }
    this.hash = hash;
    var doc = this.iframe.contentWindow.document;
    var html = '<html><body><div id="state">' + hash + '</div></body></html>';

    doc.open("text/html", this.count++ == 0 ? "replace" : undefined);
    doc.write(html)
    doc.close();
    if (this.interval == null) {
        this.interval = setInterval( function() {
            IframeHashStore._intervalCheck();
        }, 100);
    }
};

/**
 *  1.4. Link Target Locator
 */
var LinkTargetLocator = {};
LinkTargetLocator.jumpToElement = function(el) {
    if (!el) return;
    var $el = $("[id="+ el +"], [name="+ el +"]").eq(0),
        floatingMenu = $('.floating_menu'),
        linkFloatingMenu = null;
    function isMobile() {
        return rwd.ScreenSize.is(rwd.ScreenSize.SIZES.XSMALL) ||
                rwd.ScreenSize.is(rwd.ScreenSize.SIZES.SMALL);
    }
    if($el.exists()) {
        if($el.parents(".tab_content").exists()) {
            TabsControl.setTabHash("#" + $el.parents(".tab_content").eq(0).attr("id").replace("_content_","="), true);
        }
        if (floatingMenu.exists() && !isMobile()) {
            linkFloatingMenu = floatingMenu.find("[href='#"+ el +"']");
            if (linkFloatingMenu.exists()) {
                rwd.FloatingMenu.instance.scrollToLinkedSection(linkFloatingMenu);
            } else {
                fn.jumpToPosition($el.offset().top - floatingMenu.outerHeight());
            }
        } else {
            LinkTargetLocator._jumpToElement($el);
        }
    }
}
LinkTargetLocator._jumpToElement = function(el) {
    var _el = el;
    setTimeout(function() {
        if(_el.offset() != null) {
            fn.jumpTo(_el);
        } else {
            LinkTargetLocator._jumpToElement(_el);
        }
    }, 100);
}
LinkTargetLocator._onLinkClick = function(hash) {
    LinkTargetLocator.jumpToElement(hash.replace("#",""));
};

LinkTargetLocator.onDocumentReady = function() {
    var result = HashControl.getNonPairedHashElements()[0];
    result && LinkTargetLocator.jumpToElement();
};

function unescapeJavascriptCharacters(input) {
    input = input.replace(/\r\n/g, "<br>");
    input = input.replace(/%27/g, "'");
    input = input.replace(/%22/g, "\"");

    return input;
};

/**
 *  1.5. Start scripts
 */

$('#outlet-content').on('content-replace', function(event) {
    $(document).onPage("click", "A.tab_button", function(e) {
        TabsControl.setTabHash(this.href.substring(this.href.indexOf("#")));
        e.preventDefault();
        e.stopPropagation();
    });

    $('#outlet-content').find("A").not(".tab_button").not('.ajax_flow').not('.modal').not('[data-show="dialog"]').filter(function(){
        return $(this).closest(".floating_menu").length === 0;
    }).each(function(index, el) {
        var el_hashIndex = el.href.indexOf("#");
        var el_searchIndex = el.href.indexOf("?");
        var el_href_noHash = el_hashIndex > -1
            ? el.href.substring(0, el_hashIndex)
            : el.href;
        var el_href_noSearch = el_searchIndex > -1
            ? el_href_noHash.substring(0, el_searchIndex)
            : el_href_noHash;
        var el_search = el_searchIndex > -1
            ? el.href.substring(el_searchIndex)
            : null;
        var isLinkToFaq = el_search
            ? isElementParameterPresent(el_search, "?faq_id")
            : false;

        var location_searchIndex = document.location.href.indexOf("?");
        var location_noHash = document.location.href.replace(/#.*/, "");
        var location_noSearch = location_searchIndex > -1
            ? location_noHash.substring(0, location_searchIndex)
            : location_noHash;

        function isElementParameterPresent (elementParameter, nameParameter) {
            var nameParameterLength = nameParameter.length;
            return elementParameter && elementParameter.length > nameParameterLength && elementParameter.substring(0, nameParameterLength) == nameParameter;
        }
        // roznica wylacznie o hash - wniosek - w linku jest sam hash
        if (el_hashIndex > -1) {
            if (el_href_noHash == location_noHash) {
                var el_hash = el.href.substring(el_hashIndex);
                $(el).onPage("click", function(e) {
                    var location_hash = document.location.hash;
                    if (isElementParameterPresent(el_hash, "#tab")) {
                        var pair = el_hash.substring(1).split("=");
                        HashControl.setParam(pair[0], pair[1]);
                    } else if (el_hash !== "#") {
                        if (location_hash !== el_hash) {
                            HashControl.setHash(el_hash);
                        };
                        LinkTargetLocator._onLinkClick(el_hash);
                    }
                    e.preventDefault();
                    e.stopPropagation();
                })
                return;
            }
        }
        // roznica na poziomie search - hash nieistotny w porownaniu
        if (el_href_noSearch == location_noSearch && (el_href_noHash != location_noHash || isLinkToFaq)) {
            if (isElementParameterPresent(el_search, "?page")) {
                return;
            };
            $(el).onPage("click", function(e) {
                e.preventDefault();
                e.stopPropagation();
                if (isLinkToFaq) {
                    URLParameterManager.changeBrowserUrlParameter({
                        parameter: 'faq_id',
                        value: fn.getUrlParam(el_search, 'faq_id'),
                        addToHistoryBackList: false,
                        triggerEvent: true
                    });
                } else {
                    fn.changeLocation(el_href_noHash + document.location.hash);
                }
            })
            return;
        }
    });

    var callMeButtons = ['callme_button', 'callme_link', 'callme'];

    function convertButton(index, item){
        var link = document.createElement('a');

        link.href = 'tel:' + item.innerHTML.replace(/\s+/g, '');
        link.innerHTML = item.innerHTML;
        link.className = callMeButtons[i] + ' visible-xs-inline-block visible-sm-inline-block';
        item.parentNode.insertBefore(link, item);
        item.className += ' hidden-xs hidden-sm';
    }

    for (var i = 0; i < callMeButtons.length; i++) {
        $('.' + callMeButtons[i]).each(function (index, item) {
            if (item.classList.contains('visible-xs-inline-block')) {
                return;
            }
            var previousSiblingItem = item.previousElementSibling,
                nextSiblingItem = item.nextElementSibling;
            if ((!previousSiblingItem || !previousSiblingItem.classList.contains('visible-xs-inline-block')) && (!nextSiblingItem || !nextSiblingItem.classList.contains('visible-xs-inline-block'))) {
                convertButton(index, item);
            }
        });
    }

    function isMobile() {
        return rwd.ScreenSize.is(rwd.ScreenSize.SIZES.XSMALL) || rwd.ScreenSize.is(rwd.ScreenSize.SIZES.SMALL);
    }

    var cmsMenuInHeader = isMobile() ? $('.js-cms-menu-swipeable') : $('.js-menu-desktop'),
        cmsMenuInHeaderLoaded = cmsMenuInHeader.attr('loaded');

    function scrollToAnchor() {
        var hash,
            hashEl = HashControl.getNonPairedHashElements();
        if (hashEl.length >= 1) {
            hash = hashEl[0];
            LinkTargetLocator.jumpToElement(hash.replace("#",""));
        }
    }

    if (!(event.originalEvent.detail && event.originalEvent.detail.locationChangeType == 'history')) {
        if (cmsMenuInHeader.length && cmsMenuInHeaderLoaded !== 'yes') {
            CmsMenuUtils.onMenuRender(cmsMenuInHeader[0], scrollToAnchor);
        } else {
            scrollToAnchor();
        }
    }

    $(window).onPage("cms-location-change", function (event) {
        if (!event.originalEvent.detail) {
            return;
        }
        var newUrl = event.originalEvent.detail.newUrl,
            newUrlHashIndex = newUrl.indexOf("#"),
            newUrlHash = newUrlHashIndex > -1
                ? newUrl.substring(newUrlHashIndex)
                : null;
        document.activeElement.blur();
        if (!newUrlHash){
            return;
        }

        var newUrlNoHash = newUrlHashIndex > -1
                ? newUrl.substring(0, newUrlHashIndex)
                : newUrl,
            prevUrl = event.originalEvent.detail.prevUrl,
            prevUrlHashIndex = prevUrl.indexOf("#"),
            prevUrlHash = prevUrlHashIndex > -1
                ? prevUrl.substring(prevUrlHashIndex)
                : null,
            prevUrlNoHash = prevUrlHashIndex > -1
                ? prevUrl.substring(0, prevUrlHashIndex)
                : prevUrl;

        if (prevUrlNoHash === newUrlNoHash && newUrlHash !== prevUrlHash) {
            LinkTargetLocator.jumpToElement(newUrlHash.replace("#",""));
        }
    });
});

/**
 *  1.6. Modal Control
 */
var ModalControl = {};

ModalControl.blockBodyScroll = function() {
    if (rwd.ScreenSize.is(rwd.ScreenSize.SIZES.XSMALL) || rwd.ScreenSize.is(rwd.ScreenSize.SIZES.SMALL)) {
        var scrollWidth = (window.innerWidth - document.documentElement.clientWidth);
        $('body')
            .addClass("isScrollBlockedModal")
            .css('padding-right', scrollWidth + 'px');
    }
};

ModalControl.removeBlockBodyScroll = function(pos) {
    var $body = $('body');
    if ($body.hasClass("isScrollBlockedModal")) {
        $body
            .removeClass("isScrollBlockedModal")
            .css('padding-right', '')
            .delay(0)
            .queue(function() {
                var $self = $(this),
                    bodyStyle = $self.attr('style'),
                    bodyHasEmptyStyle = typeof bodyStyle === 'string' && bodyStyle === '';

                if (bodyHasEmptyStyle) {
                    $self.removeAttr('style');
                }
                    $self.dequeue();
        });
        $('body,html').animate({ scrollTop: pos, }, 0);
    }
}

/**
 * Manager pozwalający na modyfikowanie wartości parametrów w URL.
 */
var URLParameterManager = {
    /**
     * Podmienia parametry w URLu na te podane w mapie - params
     * Zmiana zachodzi bez przeładowania strony. Parametr 'addToHistoryBackList' wskazuje, czy zmiana parametru ma
     * generować nowy wpis w historii przeglądarki.
     */
    changeBrowserUrlParametersMap : function(params, addToHistoryBackList, triggerEvent) {
        if (history.pushState) {
            var url = "";
            for(var param in params) {
                if (params.hasOwnProperty(param)) {
                    var values = params[param];
                    if (Array.isArray(values)) {
                        for(var i = 0; i < values.length; i++) {
                            url += "&" + param + "=" + values[i];
                        }
                    } else {
                        url += "&" + param + "=" + values;
                    }
                }
            }
            if (url.length > 0) {
                url = "?" + url.substring(1) + window.location.hash;
            } else {
                url = window.location.hash;
            }
            if (addToHistoryBackList) {
                history.pushState(params, null, url);
            } else {
                history.replaceState(params, null, url);
            }
            if (triggerEvent) {
                $(document).trigger(URLParameterManager.URL_CHANGE_EVENT);
            }
        }
        },

    getUrlParameters : function(url) {
        var map = {};

        if (url == null || url.length == 0) {
            return map;
        }

        var array = url.substring(url.indexOf("?")+1).split("&");

        for(var i = 0; i < array.length; i++) {
            var pair = array[i];
            var index = pair.indexOf("=");
            var name = pair.substring(0, index);
            var value = pair.substring(index+1);

            if (!map[name]) {
                map[name] = [];
            }

            map[name].push(value);
        }

        return map;
    },

    /**
     * Zmienia wartość danego parametru w adresie URL prezentowanym w przeglądarce.
     * Zmiana zachodzi bez przeładowania strony. Parametr 'addToHistoryBackList' wskazuje, czy zmiana parametru ma
     * generować nowy wpis w historii przeglądarki.
     */
    changeBrowserUrlParameter : function(config) {
        if (history.pushState) {

            var parameter = config.parameter,
                value = config.value,
                addToHistoryBackList = config.addToHistoryBackList,
                triggerEvent = config.triggerEvent,
                withPathName = config.withPathName;

            var params = URLParameterManager.getUrlParameters(window.location.search);
            var pathName = window.location.pathname;
            if (value && value.length) {
                if (!(value instanceof Array)) {
                    value = [value];
                }
                params[parameter] = value;
            } else {
                delete params[parameter];
            }
            var url = "";
            for(var param in params) {
                if (params.hasOwnProperty(param)) {
                    var values = params[param];
                    for(var i = 0; i < values.length; i++) {
                        url += "&" + param + "=" + values[i];
                    }
                }
            }
            if (url.length > 0) {
                url = "?" + url.substring(1) + window.location.hash;
            } else {
                url = window.location.hash;
            }

            var stateUrl = withPathName ? pathName : url;

            if (addToHistoryBackList) {
                history.pushState(params, null, stateUrl);
            } else {
                history.replaceState({}, null, stateUrl);
            }
            if (triggerEvent) {
                $(document).trigger(URLParameterManager.URL_CHANGE_EVENT);
            }
        }
    },
    changeBrowserAllUrlParameters : function(parametersStr, addToHistoryBackList, triggerEvent) {
        if (history.pushState) {
            var url = parametersStr;
            if (url.length > 0) {
                url = "?" + url + window.location.hash;
            } else {
                url = window.location.href.split("?")[0] + window.location.hash;
            }
            if (addToHistoryBackList === true) {
                history.pushState(parametersStr, null, url);
            } else {
                history.replaceState(parametersStr, null, url);
            }
            if (triggerEvent) {
                $(document).trigger(URLParameterManager.URL_CHANGE_EVENT);
            }
        }
    },
    /**
     * Usuwa wskazany parametr z URL. Zmiana zachodzi bez przeładowania strony.
     * Parametr 'addToHistoryBackList' wskazuje, czy zmiana parametru ma generować nowy wpis w historii przeglądarki.
     */
    removeBrowserUrlParameter: function(config) {
        var parameter = config.parameter,
            addToHistoryBackList = config.addToHistoryBackList,
            triggerEvent = config.triggerEvent,
            withPathName = config.withPathName;
        URLParameterManager.changeBrowserUrlParameter({
            parameter: parameter,
            value: null,
            addToHistoryBackList: addToHistoryBackList,
            triggerEvent: triggerEvent,
            withPathName: withPathName
        });
        if (triggerEvent) {
            $(document).trigger(URLParameterManager.URL_CHANGE_EVENT);
        }
    },

    /**
     * Pobranie parametru z urla
     */
    getBrowserUrlParameter: function (name, explicitUrl) {
        var results;
        if (explicitUrl !== null && explicitUrl !== undefined) {
            results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(explicitUrl);
        } else {
            results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        }
        if (results == null) {
            return null;
        } else {
            return results[1] || 0;
        }
    },
    URL_CHANGE_EVENT: "url-change-event"
};

function getIEVersion() {
    var sAgent = window.navigator.userAgent;
    var Idx = sAgent.indexOf("MSIE");

    // If IE, return version number.
    if (Idx > 0)
        return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)));

    // If IE 11 then look for Updated user agent string.
    else if (!!navigator.userAgent.match(/Trident\/7\./))
        return 11;

    else
        return -1; //It is not IE
}

function isIE() {
    return getIEVersion() !== -1;

}

/**
 * Tworzy zmienną, na której zmiany można nasłuchiwać
 */
function Observable(initValue) {
    var listeners = [];
    var value = initValue;
    this.getValue = function () {
        if (Array.isArray(value)) {
            return value.slice();
        }
        return value;
    };
    this.setValue = function (newValue) {
        value = newValue;
        listeners.forEach(function (listener) {
            listener(value);
        });
    };
    this.addListener = function () {
        for (var i = 0; i < arguments.length; i++) {
            listeners.push(arguments[i]);
        }
    };
}

/**
 * Funkcja pomocnicza przy sklejaniu htmla, zwraca obiekt jQuery
 * @param {string} elementType Nazwa wstawianego tagu
 * @param {object} attributes Obiekt z atrybutami dla tagu (np. {class: "hidden"})
 * @param {object} innerHtml Opcjonalne argumenty zawierające stringi lub obiekty jQuery do wstawienia jako inner html
 */
function html(elementType, attributes /*, innerHtml...*/) {
    var element = $("<" + elementType + "></" + elementType + ">");
    for(var property in attributes) {
        element = element.attr(property, attributes[property]);
    }
    for (var i = 2; i < arguments.length; i++) {
        element = element.append(arguments[i]);
    }
    return element;
}

var KeypressUtils = {
    BACKSPACE:    8,
    ENTER:        13,
    SHIFT:        16,
    TAB:        9,
    ALT:        18,
    PAUSE:        19,
    CAPSLOCK:    20,
    ESCAPE:        27,
    PAGEUP:        33,
    PAGEDOWN:    34,
    END:        35,
    HOME:        36,
    LEFTARROW:    37,
    UPARROW:    38,
    RIGHTARROW:    39,
    DOWNARROW:    40,
    INSERT:        45,
    DEL:        46,
    CTRL:        17,
    SPACE: 32,
    handleKey: function(eventHandler, keyCode) {
        return function(e) {
            return e.keyCode === keyCode ? eventHandler(e) : true;
        }
    }
};

function renderPagination(resultsNumber, curPage) {
    if (resultsNumber === 0) {
        return;
    }

    function renderPage(pageNum) {
        if (pageNum === curPage) {
            return $("<li>").append(
                $("<span>", {
                    "data-page-id": pageNum,
                    "aria-current": "page",
                    "class": "pagination-link s-link page_current",
                    text: pageNum
                })
            );
        }

        return $("<li>").append(
            $("<a>", {
                "data-page-id": pageNum,
                "aria-label": `Przejdź do strony ${pageNum}`,
                "class": "pagination-link s-link",
                "href": "#",
                text: pageNum
            })
        );
    }

    function renderSelect(startPage, endPage) {
        const $selectWrapper = $("<li>");

        const $hiddenLabel = $("<label>", {
            class: "sr-only",
            for: `pagination-select-${curPage}`,
            text: "Wybierz numer strony"
        });

        const $select = $("<select>", {
            "id": `pagination-select-${curPage}`,
            "aria-label": "Wybierz numer strony"
        });

        const $placeholderOption = $("<option>", {
            value: "",
            text: "...",
            disabled: true,
            selected: true,
            hidden: true
        });
        $select.append($placeholderOption);

        for (let pageNum = startPage; pageNum <= endPage; pageNum++) {
            const $option = $("<option>", {
                value: pageNum,
                text: pageNum,
                "data-page-id": pageNum,
                selected: pageNum === curPage
            });
            $select.append($option);
        }

        $selectWrapper.append($hiddenLabel).append($select);
        return $selectWrapper;
    }

    const paginationWindow = 2;
    const pageSize = 10;
    const pagesCounter = Math.ceil((resultsNumber - 0.5) / pageSize);

    const $paginationContainer = $("<nav>", {
        class: "list-pagination",
        "aria-label": "Paginacja"
    });
    const $paginationList = $("<ul>", {
        class: "pagination-list",
        role: "list"
    });

    if (curPage > 1) {
        const $prevButton = $("<li>").append(
            $("<a>", {
                class: "pagination-link s-link pager-prev",
                href: "#",
                "data-page-id": curPage - 1,
                "aria-label": "Poprzednia strona",
                html: '<span class="sr-only">Poprzednia strona</span><span aria-hidden="true" class="web-icon icon-arrow"></span>'
            })
        );
        $paginationList.append($prevButton);
    }

    let i = 1;
    $paginationList.append(renderPage(i));

    if (curPage - paginationWindow > 3) {
        const selectEnd = curPage - paginationWindow - 1;
        $paginationList.append(renderSelect(2, selectEnd));
        i = curPage - paginationWindow;
    } else {
        i = 2;
    }

    for (; i <= curPage + paginationWindow && i <= pagesCounter; i++) {
        $paginationList.append(renderPage(i));
    }

    if (i < pagesCounter - 1) {
        const selectStart = i;
        const selectEnd = pagesCounter - 1;
        $paginationList.append(renderSelect(selectStart, selectEnd));
        i = selectEnd + 1;
    }

    if (i <= pagesCounter) {
        $paginationList.append(renderPage(pagesCounter));
    }

    if (curPage < pagesCounter) {
        const $nextButton = $("<li>").append(
            $("<a>", {
                class: "pagination-link s-link pager-next",
                href: "#",
                "data-page-id": curPage + 1,
                "aria-label": "Następna strona",
                html: '<span class="sr-only">Następna strona</span><span aria-hidden="true" class="web-icon icon-arrow"></span>'
            })
        );
        $paginationList.append($nextButton);
    }

    $paginationContainer.append($paginationList);
    return $paginationContainer;
}


function debounce(callback, period) {
    var mutex = false;
    var dirty = false;
    return function() {
        if (mutex) {
            dirty = true;
            return
        }
        mutex = true;
        callback();
        setTimeout(function() {
            if (dirty) {
                callback();
            }
            dirty = false;
            mutex = false;
        }, period);
    }
}

function Autocompleter($input,
                       $suggestionsContainer,
                       refreshSuggestions) {

    var upKeyHandler = KeypressUtils.handleKey(moveUp, KeypressUtils.UPARROW);
    var downKeyHandler = KeypressUtils.handleKey(moveDown, KeypressUtils.DOWNARROW);
    var enterKeyHandler = KeypressUtils.handleKey(selectSuggestion, KeypressUtils.ENTER);
    var debouncedRefresh = debounce(refreshSuggestions, 200);
    var changeHandler = function(e) {
        debouncedRefresh(e);
        return true;
    }

    this.activate = function() {
        $input.onPage("input", changeHandler);
        $input.onPage("keydown", upKeyHandler);
        $input.onPage("keydown", downKeyHandler);
        $input.onPage("keypress", enterKeyHandler);
        $input.onPage("blur", clearSuggestions);
    }

    this.deactivate = function() {
        $input.offPage("input", changeHandler);
        $input.offPage("keydown", upKeyHandler);
        $input.offPage("keydown", downKeyHandler);
        $input.offPage("keypress", enterKeyHandler);
        $input.offPage("blur", clearSuggestions);
        clearSuggestions();
    }

    function clearSuggestions() {
        var clear = setTimeout(function() {
            $suggestionsContainer
                .hide()
                .empty();
            clearTimeout(clear);
        }, 150);
    }

    function moveUp() {
        move(-1);
    }

    function moveDown() {
        move(1);
    }

    function move(direction) {
        var childrenNumber = $suggestionsContainer.children().length;
        var index = direction === 1 ? 1 : childrenNumber;
        if ($suggestionsContainer.find(".active").length) {
            index = $suggestionsContainer.find(".active").prevAll().length + 1 + direction;
            $suggestionsContainer.children().removeClass("active");
        }
        $suggestionsContainer.children(":nth-child(" + index + ")").addClass("active");
    }

    function selectSuggestion() {
        $suggestionsContainer.find(".active").trigger("click");
        return false;
    }

}

var AnimationUtils = AnimationUtils || {};
AnimationUtils = {
    fadeInRight: function(options) {

        var settings = $.extend({
            component: null,
            itemSelector: null,
            interval: 150,
            animationStep: 1,
            isAnimated: 'isAnimated',
            startOnCondition: true,
        }, options);

        if (typeof settings.component !== 'object') {
            throw new Error([
                'AnimationUtils - fadeInRight',
                'component propertis should by an jquery object type',
                'but received',
                typeof settings.component
            ].join(' '))
        }

        if (typeof settings.itemSelector !== 'string') {
            throw new Error([
                'AnimationUtils - fadeInRight',
                'itemSelector propertis should by an string type',
                'but received',
                typeof settings.itemSelector
            ].join(' '))
        }


        if ( settings.startOnCondition ) {
            let items = settings.component.find(settings.itemSelector),
                itemsLength = items.length,
                itemsIterator = 0,
                animation = setInterval(function() {
                let item = $(items[itemsIterator]);

                item.addClass(settings.isAnimated);

                if (itemsIterator >= itemsLength) {
                    clearInterval(animation)
                }
                itemsIterator+=settings.animationStep;
            }, settings.interval);
        }
    }
}

window.rwd || (window.rwd = {});

;(function($package) {
/**
 *  1.7. Time variables
 */

    const setTimeoutDelay = {
        td100: 100,
        td120: 120,
        td200: 200
    };

    $package.timeoutDelay = {...setTimeoutDelay}
})(rwd);

;(function($package) {
    var integerAnimationItem = {
        run: function($item, nameActiveClass) {
            // Dodanie aktywnego elementu
            const closestComponent = $item.closest('.component');
            const closestList = $item.closest(".tabs_menu");
            const decorativeLineClass = 'item_decorativeLine'
            const decorativeLine = $('<div>', {
                class: decorativeLineClass
            });

            !closestComponent.find('.' + decorativeLineClass).exists() && closestList.after(decorativeLine);
            const $decorativeLine = closestList.closest(".component").find('.' + decorativeLineClass),
                  $decorativeLineComp = $decorativeLine.closest(".component");

            let widthInitDecor= closestList.find("." + nameActiveClass).outerWidth(),
                posOffsetParent = $decorativeLineComp.offset(),
                posOffsetInit = $decorativeLineComp.find("." + nameActiveClass).offset(),
                posSetInit = posOffsetInit.left - posOffsetParent.left;

            $decorativeLine.css({
                width: widthInitDecor,
                left: posSetInit
            });

            $item.onPage('click focus', function(e) {
                let posOffsetActive = $(this).offset(),
                    widthActiveItem= $(this).outerWidth(),
                    posSetActive = posOffsetActive.left - posOffsetParent.left
                    transitionCss = "all ease-in-out 0.5s";
                $decorativeLine.css({
                    left: posSetActive,
                    width: widthActiveItem,
                    transition: transitionCss
                });
            });

        }
    };
    $package.integerAnimationItem = integerAnimationItem;
})(rwd);

;(function($package) {
    const DEFAULT_EXPAND_LABEL = "Rozwiń";
    const DEFAULT_COLLAPSE_LABEL = "Zwiń";
    const ANIMATION_SPEED = "medium";
    const IS_OPACITY_ANIMATE = "isOpacityAnimate";
    const IS_BLOCk = "isBlock";
    const IS_EXPANDED = "isExpanded";
    const IS_ADVANCED = "isAdvanced";
    const EXPAND_HEAD_BUTTON = $("<button>", {
        "class": "expand_element__headButton"
    });
    const DISPLAY_NONE = {
        display: "none",
      };
    const getLabels = (expandElement) => {
        const expandElementHeadText = expandElement.find('.expand_element__headButton').first().html();
        if (!expandElement.attr('data-expand-label')) {
            expandElement.attr('data-expand-label', expandElementHeadText);
        }
        const labelExpand = expandElement.attr('data-expand-label') || DEFAULT_EXPAND_LABEL;
        const labelCollapse = expandElement.attr('data-collapse-label') || DEFAULT_COLLAPSE_LABEL;
        return {
            labelExpand,
            labelCollapse
        }
    }
    const removeUnnecesaryDisplayNone = (element) => {
        if (element.css('display') === 'none') {
            return element.css('display', '');
        }
        return null;
    }
    const setLabels = (expandElement, expandElementHead) => {
        const HEAD_LABELS = getLabels(expandElement);
        return expandElement.hasClass(IS_EXPANDED)
            ? expandElementHead.html(HEAD_LABELS.labelCollapse)
            : expandElementHead.html(HEAD_LABELS.labelExpand);
    }
    const basicExpandElementAnimation = ( $expandElement ) => {
        const EXPAND_ELEMENT = $expandElement;
        const EXPAND_ELEMENT_BODY = EXPAND_ELEMENT.find(".body");
        const EXPAND_ELEMENT_HEAD = EXPAND_ELEMENT.find(".head");
        EXPAND_ELEMENT.addClass(IS_ADVANCED);
        const EXPANDED_ELEMENTS = EXPAND_ELEMENT_BODY.parent().css(DISPLAY_NONE).children();
        removeUnnecesaryDisplayNone(EXPANDED_ELEMENTS);
        EXPANDED_ELEMENTS.addClass(IS_BLOCk);
        EXPAND_ELEMENT_HEAD.wrapInner(EXPAND_HEAD_BUTTON);
        const EXPAMD_HEAD_BUTTON = EXPAND_ELEMENT_HEAD.find('.expand_element__headButton');
        EXPAMD_HEAD_BUTTON.onPage("click", function () {
          const $self = $(this);
          const EXPAND_ELEMENT= $self.closest(".expand_element");
          const EXPAND_ELEMENT_HEAD = EXPAND_ELEMENT.find(".expand_element__headButton").first();
          const TOGGLED_ELEMENT = EXPAND_ELEMENT.find("> tbody > tr > .body");
          const PARENT_OF_HIDDEN_ELEMENT = TOGGLED_ELEMENT.parent();
          TOGGLED_ELEMENT.removeClass(IS_OPACITY_ANIMATE);
          EXPAND_ELEMENT.toggleClass(IS_EXPANDED);
          PARENT_OF_HIDDEN_ELEMENT.stop(true, true).slideToggle(
            ANIMATION_SPEED,
            () => {
              TOGGLED_ELEMENT.is(":visible")
                ? TOGGLED_ELEMENT.addClass(IS_OPACITY_ANIMATE)
                : TOGGLED_ELEMENT.removeClass(IS_OPACITY_ANIMATE);
            }
          );
          setLabels(EXPAND_ELEMENT, EXPAND_ELEMENT_HEAD);
        });
      };
      $package.basicExpandElementAnimation = basicExpandElementAnimation;
})(rwd);

;(function($package) {
    const basicExpandContentWCAGAnimation = ($componentElement) => {
        const $expandContent = $componentElement.find(".expand_content");
        if ($expandContent.length === 0) return;

        const $expandedButton = $expandContent.find(".expand_content__button");

        $expandContent.each(function() {
            const $this = $(this);
            const $expandText = $this.attr("data-expand");
            const $dataAriaExpand = $this.attr("data-aria-expand");
            $this.find(".expand_content__text").text($expandText);
            $this.find(".expand_content__button").attr('aria-label', $dataAriaExpand);
        });

        $expandContent.find(".expand_content__items").slideUp();

        $expandedButton.on("click", function() {
            const $this = $(this);

            const $closestExpandedWrapper = $this.closest('.expand_content');
            const $collapseText = $closestExpandedWrapper.attr('data-collapse');
            const $expandText = $closestExpandedWrapper.attr('data-expand');
            const $dataAriaExpand = $closestExpandedWrapper.attr('data-aria-expand');
            const $dataAriaCollapse = $closestExpandedWrapper.attr('data-aria-collapse');
            const $text = $this.find('.expand_content__text');

            $closestExpandedWrapper.toggleClass("state-active")
                .find(".expand_content__items")
                .first()
                .slideToggle();

            const $isExpanded = $closestExpandedWrapper.hasClass('state-active');
            const $ariaLabel = $isExpanded ? $dataAriaCollapse : $dataAriaExpand;
            $this
                .attr('aria-expanded', $isExpanded)
                .attr('aria-label', $ariaLabel)
                .toggleClass('flipped', $isExpanded);

            const $contentItems = $this
                .closest('.expand_content')
                .find('.expand_content__items');
            $contentItems.attr('aria-hidden', !$isExpanded);

            $isExpanded ? $text.text($collapseText) : $text.text($expandText);
        });
    }
    $package.basicExpandContentWCAGAnimation = basicExpandContentWCAGAnimation;
})(rwd);

window.numberUtils || (window.numberUtils = {});
;(function($package) {
    const isNumeric = function(number, decimalSeparator) {
        var numberString = decimalSeparator !== undefined ? number.replace(decimalSeparator, '.') : number;
        return !isNaN(parseFloat(numberString)) && isFinite(numberString);
    };
    $package.isNumeric = isNumeric;
})(numberUtils)


;(function($package) {
    /***  1.8. Utils */
    $package.handleListNavigation = (listElementWrapper) => {
        listElementWrapper.addEventListener('keydown', (event) => {
            const activeElement = document.activeElement;
            const links = Array.from(listElementWrapper.querySelectorAll('a'));

            if (activeElement.tagName.toLowerCase() === 'a' && listElementWrapper.contains(activeElement)) {
                let currentIndex = links.indexOf(activeElement);
                let nextIndex;

                if (event.key === 'ArrowDown') {
                    nextIndex = (currentIndex + 1) % links.length;
                } else if (event.key === 'ArrowUp') {
                    nextIndex = (currentIndex - 1 + links.length) % links.length;
                }

                if (nextIndex !== undefined) {
                    links[nextIndex].focus();
                }
            }
        });

        const links = listElementWrapper.querySelectorAll('a');
        links.forEach(link => {
            link.setAttribute('tabindex', '0');
        });
    };
})(rwd);

;(function($package) {
    const replaceText = (element, text) => {
        element.contents().each((_, node) => {
            if (node.nodeType === Node.TEXT_NODE) {
                node.textContent = text;
            }
        })
    };
    $package.replaceText = replaceText;
})(rwd)
