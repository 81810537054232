/**************************************************************************************************
 * (1) 1. Mechanizm inicjalizacji javascriptowej komponentów
 *************************************************************************************************/

/**
 * Zarządza inicjalizacją javascript dla poszczególnych typów komponentów.
 */
var ComponentsManager = function() {

    /**
     * Zarządcy inicjujący poszczególne typy komponentów.
     */
    var managers = {};

    /**
     * Wspólna inicjalizacja komponentów.
     */
    var commonInitialization = null;

    /*
     * Lista id timeoutów, zarejestrowanych przez komponenty na danej stronie
     */
    var timeouts = {
        CONTENT: [],
        HEADER: [],
        FOOTER: []
    };

    /*
     * Lista id interwałów, zarejestrowanych przez komponenty na danej stronie
     */
    var intervals = {
        CONTENT: [],
        HEADER: [],
        FOOTER: []
    };


    /*
    * Konfiguracja dla obsługi eventów trzech slotów
    */
    var outletConfig = {
        CONTENT: {
            name: 'CONTENT',
            offFunction: fn.offPage,
            element: $('#outlet-content')
        },
        HEADER: {
            name: 'HEADER',
            offFunction: fn.offHeaderEvent,
            element: $('#outlet-header')
        },
        FOOTER: {
            name: 'FOOTER',
            offFunction: fn.offFooterEvent,
            element: $('#outlet-footer')
        }
    };

    /**
     * Przelotka do metody setTimeout rejestrująca id timera
     */
    function setPageTimeout(/* */) {
        var id = setTimeout.apply(null, arguments);
        timeouts['CONTENT'].push(id);
        return id;
    }
    /*
     * Przelotka do metody setInterval rejestrująca id timera
     */
    function setPageInterval(/* */) {
        var id = setInterval.apply(null, arguments);
        intervals['CONTENT'].push(id);
        return id;
    }

    /**
     * Przelotka do metody setTimeout rejestrująca id timera
     */
    function setHeaderTimeout(/* */) {
        var id = setTimeout.apply(null, arguments);
        timeouts['HEADER'].push(id);
        return id;
    }
    /*
     * Przelotka do metody setInterval rejestrująca id timera
     */
    function setHeaderInterval(/* */) {
        var id = setInterval.apply(null, arguments);
        intervals['HEADER'].push(id);
        return id;
    }

    /**
     * Przelotka do metody setTimeout rejestrująca id timera
     */
    function setFooterTimeout(/* */) {
        var id = setTimeout.apply(null, arguments);
        timeouts['FOOTER'].push(id);
        return id;
    }
    /*
     * Przelotka do metody setInterval rejestrująca id timera
     */
    function setFooterInterval(/* */) {
        var id = setInterval.apply(null, arguments);
        intervals['FOOTER'].push(id);
        return id;
    }

    /**
     * Podpina funkcję dla ogólnej inicjalizacji komponentów.
     */
    function setCommonInitialization(initialization) {
        commonInitialization = initialization;
    }

    /**
     * Rejestruje zarządcę do inicjalizacji danego typu komponentu.
     * Uruchamia metodę init zarządcy w celu inicjalizacji komponentu.
     */
    function register(componentTypeCode, componentManager) {
        if (componentManager instanceof Function) {
            componentManager = componentManager();
        }
        managers[componentTypeCode] = componentManager;
    }

    /**
     * Inicjalizuje dany komponent.
     * Uwaga: jeżeli komponent był już zainicjalizowany, to zdarzenia mogą się podpiąć drugi raz.
     */
    function initComponent(component, slotObj) {
        var $slot = slotObj || $('#outlet-content'),
            $componentElement = $slot.find(component),
            componentTypeCode, componentId, manager;

        if ($componentElement.length) {
            if (commonInitialization) {
                commonInitialization($componentElement);
            }
            componentTypeCode = $componentElement.data('component-type-code');
            componentId = $componentElement.data('component-id');
            manager = managers[componentTypeCode];
            if (manager && manager.init) {
                manager.init(componentId, $componentElement, $componentElement.data('js-parameters'));
            }
            if (isBuilder()) {
                unbindEvents($componentElement, false);
            }
        }
    }

    /**
     * Inicjalizuje wszystkie komponenty znajdujące się w przekazanym elemencie głównym.
     */
    function initComponents(slotObj) {
        if (commonInitialization) {
            commonInitialization(slotObj);
        }

        slotObj.find('.component').each(function() {
            initComponent($(this), slotObj);
        });
    }

    /**
     * Czy jesteśmy w podglądzie strony.
     */
    function isPreview() {
        var regex = new RegExp('/_cms.*/preview/');
        return regex.test(window.location.pathname);
    }

    /**
     * Czy jesteśmy w builderze
     */
    function isBuilder() {
        return window.frameElement && window.frameElement.id === "preview_frame"
    }

    /**
     * Odpięcie zdarzeń, które potencjalnie mogą zmienić adres strony.
     * @param allEvents true - odpiąć wszystkie zdarzenia,
     * false - tylko te które nie są oznaczone, że nia mają być odpięte
     */
    function unbindEvents(rootElement, allEvents) {
        rootElement.find('A, .linked_content').each(function() {
            var $this = $(this);
            if (allEvents || !$this.data('doNotUnbind')) {
                $this.removeAttr('onclick').off('click').on("click", function(e) {
                    $(this).parents('.component_wrapper').trigger("click");
                    e.stopPropagation();
                    return false;
                });
            }
        });
    }

    /*
     * Zapięcie obsługi handlerów obsługujących 'dynamiczne przeładowanie' strony
     * - inicjalizacja komponentów
     * - czyszczenie eventów strony
     * - czyszczenie eventów czasowych
     * - czyszczenie wpiętych elementów modali
     */

    function bindClearEvents(configSection) {
        function clearEvents(pageElements) {
            configSection.offFunction.apply(pageElements);
            configSection.offFunction.apply($(window));
            configSection.offFunction.apply($(document));
        }

        function clearTimingEvents(pageElements) {
            $.each(timeouts[configSection.name], function (i, id) {
                clearTimeout(id);
            });

            timeouts = {
                CONTENT: [],
                HEADER: [],
                FOOTER: []
            };

            $.each(intervals[configSection.name], function (i, id) {
                clearInterval(id);
            });
            intervals = {
                CONTENT: [],
                HEADER: [],
                FOOTER: []
            };

            pageElements.stop(true);
        }

        function clearModals(pageElements) {
            let $modalPopup = pageElements.find('.modal_popup');
            $modalPopup.each(function(modal) {
                if ( modal.length > 0 ) {
                    let $self = $(this);
                    let modalComponent = $self.find('.js-modalContent');
                    $self.before(modalComponent);
                    $self.remove();
                }
            });
            pageElements
                .find(".modal_popup_underlay, #get_ingmobile_app_wrapper, #defaultModalPopupCover")
                .remove();
        }

        configSection.element.on('content-replace-before', function () {
            var pageElements = $('*');
            clearModals(pageElements);
            clearEvents(pageElements);
            clearTimingEvents(pageElements);
        });
    }

    function bindInitEvents(configSection) {
        configSection.element.on('content-replace', function () {
            initComponents(configSection.element);
        });
    }

    function unbindTemplateEvents() {
        var contentWrapper = $('#content-wrapper');
        contentWrapper.on('before-template-change', function () {
            var pageElements = $('*');
            fn.offTemplateEvent.apply(pageElements);
        });
    }

    Object.keys(outletConfig).forEach(function (key) {
        bindInitEvents(outletConfig[key]);
        bindClearEvents(outletConfig[key]);
    });
    unbindTemplateEvents();

    return {
        setPageTimeout: setPageTimeout,
        setPageInterval: setPageInterval,
        setHeaderTimeout: setHeaderTimeout,
        setHeaderInterval: setHeaderInterval,
        setFooterTimeout: setFooterTimeout,
        setFooterInterval: setFooterInterval,
        setCommonInitialization: setCommonInitialization,
        initComponents: initComponents,
        initComponent: initComponent,
        register: register,
        isPreview: isPreview,
        isBuilder: isBuilder,
        unbindEvents: unbindEvents
    };
}();

/**
 * Metoda oznaczająca obiekty z podpiętym zdarzeniem "click", które nie będzie odpięte w podglądzie.
 */
$.fn.previewLink = function() {
    var link = $(this);
    link.data('doNotUnbind', true).find('A, .linked_content').data('doNotUnbind', true);
    return link;
};

/**************************************************************************************************
 * (2) 1.1. Ogólna inicjalizacja komponentów
 **************************************************************************************************/

 /**************************************************************************************************
 * UWAGA: uważać przy delegacji zdarzeń do rodziców komponentów,
 * bo ta metoda jest też wywoływana w kontekście pojedynczych komponentów
 * (odświeżanych po zmianie konfiguracji).
 *************************************************************************************************/
ComponentsManager.setCommonInitialization(function(rootElement) {
    // zamiana klasy 'initialHide' na 'display:none'
    rootElement.find('.initialHide').hide().removeClass('initialHide');

    // otworzenie okna z podglądem wydruku / plikiem pdf
    rootElement.find('.print, .save_as_pdf').on("click", function() {
      var $this = $(this);
      if ($this.attr('class').match(/\bmode_/)) {
        var mode = $this.getFirstMatchingClass(/\bmode_/);
        var search = (document.location.search === '') ? '?' : '&';
        fn.openWindow(document.location.href.replace(/#.+/, '') + search + mode.replace('_', '=') + document.location.hash);
        return false;
      }
    });

    // prompt z oknem drukowania i blokada akcji na elementach strony
    rootElement.find('body#print').each(function() {
      window.print();
      ComponentsManager.unbindEvents($(this), true);
    });

    // popup
    rootElement.on('click', '.popup_box_link', function() {
        var $this = $(this);
        $this.parents('.popup_root').find('.popup_box').show();
    });

    // popup
    rootElement.on('click', '.hide_popup', function() {
        var $this = $(this);
        $this.parents('.popup_root').find('.popup_box').hide();
    });

    // podlinkowanie treści
    rootElement.on('click', '.linked_content[data-content-link]', function() {
        fn.changeLocation($(this).data('content-link'));
    });

    $(rootElement).each(function () {
        const cmsMenuSecondLevel = $(this).find('.header .js-cms-menu');

        if (cmsMenuSecondLevel.length === 0) {
            return;
        }

        CmsMenuUtils.onMenuRender(cmsMenuSecondLevel[0], function() {
            const buttons = cmsMenuSecondLevel.find('.secondMenuHeader__button');
            const linksWithLayers = cmsMenuSecondLevel.find('.secondMenuHeader__link');
            const linksWithoutLayers = cmsMenuSecondLevel.find('> .menu_list > .node > a');

            const toggleSubmenu = (element, show) => {
                const node = element.closest('.node');
                const submenu = node.find('.second_level_component');

                if (show) {
                    submenu.show();
                    element.attr('aria-expanded', 'true');
                    element.parent().addClass('isExpanded');
                } else {
                    submenu.hide();
                    element.attr('aria-expanded', 'false');
                    element.parent().removeClass('isExpanded');
                }
            };

            const closeAllOpenMenus = () => {
                rootElement.find('.secondMenuHeader__button[aria-expanded="true"]').each((index, element) => {
                    toggleSubmenu($(element), false);
                });
            };

            const handleMenuToggle = (element) => {
                const ariaExpanded = element.attr('aria-expanded') === 'true';
                closeAllOpenMenus();
                toggleSubmenu(element, !ariaExpanded);
            };

            // Obsługuje kliknięcia
            buttons.on("click", function() {
                handleMenuToggle($(this));
            });

            const handleMouseLeave = (element) => {
                const node = element.closest('.node');
                const submenu = node.find('.second_level_component');

                setTimeout(() => {
                    if (!submenu.is(':hover') && !element.is(':hover')) {
                        submenu.hide();
                        element.parent().removeClass('isExpanded');

                        node.find('[aria-expanded="true"]').each(function() {
                            $(this).attr('aria-expanded', 'false');
                        });
                    }
                }, 100);
            };

            linksWithLayers.on("mouseenter", (e) => {
                toggleSubmenu($(e.currentTarget), true);
            });

            linksWithLayers.on("mouseleave", (e) => {
                handleMouseLeave($(e.currentTarget));
            });

            // Obsługuje 'mouseleave' dla podmenu
            rootElement.find('.second_level_component').on("mouseleave", (e) => {
                handleMouseLeave($(e.currentTarget).closest('.node').find('.secondMenuHeader__link'));
            });

            const allLinkMenu = linksWithLayers.add(linksWithoutLayers);

            allLinkMenu.on("focusin", () => {
                closeAllOpenMenus();
            });
        });
    });

});
